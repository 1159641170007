
.App {
  text-align: center;
  min-width: 85vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.headerImg {
  display: block;
  margin-top: 30px;
  opacity: 0.75;
}

.App-header {
  background-color: #282c34;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.appWrapper {
  max-width: 200px;
  min-width: 98%;
  text-align: center;
  margin-top: 4em;
  display: inline-block;
}

.appWrapperSummary {
  max-width: 200px;
  min-width: 98%;
  text-align: center;
  margin-top: 4em;
  display: inline-block;
}

.mainTitle {
  color: rgb(255, 255, 255, 0.85);
  font-weight: 300;
  font-size: 26pt;
  line-height: 28pt;
}

.nameHeader {
  color: rgb(255, 255, 255, 0.85);
  font-weight: 500;
  font-size: 14pt;
}

.infoText {
  color: rgb(255, 255, 255, 0.65);
  font-weight: 200;
  font-size: 13pt;
}

.subTitle {
  color: rgb(182, 228, 149);
  font-weight: 500;
  font-size: 9pt;
  padding-left: 2px;
}

.infoWrapper {
  border-radius: 12px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 180px));
  grid-auto-rows: 1fr, 1fr;
  width: 100%;
}

.nameContainer {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-bottom: 4px;
}
.nameText {
  color: #333;
  font-weight: bold;
  font-size: 1.4em;
  text-align: left;
  padding-left: 16px;
  padding-top: 8px;
  white-space: nowrap;
  color: rgb(255, 255, 255, 0.75);
}

.addressContainer {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 2;
  display: flex;
  justify-content: left;
  align-items: top;
  padding-bottom: 14px;
}
.addressText {
  color: rgb(255,255,255,.35);
  font-size: small;
  text-align: left;
  padding-left: 16px;
}

.handicapContainer {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 3;
  grid-row-end: 3;
  display: flex;
  justify-content: left;
  align-items: flex-end;
}
.handiLabel {
  font-weight: 400;
  font-size: 1em;
  padding-left: 16px;
  padding-bottom: 12px;
  color: rgb(255, 255, 255, 0.45);
}
.handiNum {
  font-weight: 800;
  font-size: 1.2em;
  padding-left: 4px;
  padding-bottom: 10px;
  color: rgb(182, 228, 149, 0.75);
}

.betContainer {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 4;
  display: flex;
  justify-content: center;
  align-items: center;
}
.betText {
  font-weight: bold;
  font-size: 2.8em;
  text-align: center;
  color: rgb(255, 255, 255, 0.75);
}

.tapContainer {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.tapToEdit {
  color: rgb(182, 228, 149);
  font-weight: 300;
  font-size: small;
}

.betMessage {
  color: rgb(255, 255, 255, .55);
  font-weight: 600;
  font-size: .75em;;
  text-align: left;
  padding-left: 2px;
}
.betEth {
  color: rgb(182, 228, 149);
  font-weight: 600;
  font-size: 1.5em;
  text-align: left;
  padding-left: 2px;
}
.betCurrent {
  color: rgb(255, 255, 255, .85);
  font-size: small;
  text-align: left;
  padding-left: 2px;
}

.addContainer {
  position: absolute;
  top: 7em;
  right: 2em;
}

.coordinatorAddress {
  color: rgb(255, 255, 255, 0.5);
  font-weight: 600;
  font-size: small;
  text-align: left;
}

.scoringWrapper {
  display: grid;
  grid-template-columns: 1fr, 1fr, 1fr;
  grid-auto-rows: 1fr, 1fr, 1fr;
  margin-left: 1em;
  margin-right: 1em;
}

.scoringName {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
  display: flex;
  justify-content: left;
  align-items: left;
}

.playerName {
  font-weight: bold;
  font-size: 1.8em;
  text-align: left;
  white-space: nowrap;
  color: rgb(255, 255, 255, 0.85);
}

.headerCaps {
  color: rgb(123, 209, 65);
  font-weight: 500;
  font-size: 9pt;
  padding-left: 0px;
  letter-spacing: 2pt;
}

.scoringHeaderFront {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 2;
  display: flex;
  justify-content: left;
  align-items: left;
}

.scoringHeaderBack {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
  display: flex;
  justify-content: left;
  align-items: left;
}

.scoringHeaderTotal {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 2;
  display: flex;
  justify-content: left;
  align-items: left;
}

.scoringValueFront {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 3;
  grid-row-end: 3;
  display: flex;
  justify-content: left;
  align-items: left;
}
.scoringValueBack {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 3;
  display: flex;
  justify-content: left;
  align-items: left;
}
.scoringValueTotal {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 3;
  display: flex;
  justify-content: left;
  align-items: left;
}

.scoringGross {
  font-weight: 800;
  font-size: 2em;
  color: rgb(182, 228, 149);
}

.scoringNet {
  font-weight: 800;
  font-size: 1.2em;
  margin-top: 14px;
  color: rgb(255, 255, 255, 0.45);
}

.scoringSeperator {
  font-weight: 800;
  font-size: 1.2em;
  margin-top: 14px;
  color: rgba(255, 255, 255, 0.15);
}

.resultsTitle {
  text-align: left;
}

.resultsDollar {
  font-weight: bold;
  font-size: 2.5em;
  line-height: 1.2em;
  margin-right: 6px;
  color: rgb(182, 228, 149);
}

.resultsName {
  font-weight: 200;
  font-size: 2.2em;
  text-align: left;
  color: rgb(255, 255, 255, 0.75);
}

.resultsValues {
  text-align: left;
}

.resultsGross {
  font-weight: 400;
  font-size: 1.1em;
  text-align: left;
  color: rgb(255, 255, 255, 0.85);
}

.resultsNet {
  font-weight: 400;
  font-size: 0.9em;
  margin-top: 3px;
  color: rgb(255, 255, 255, 0.25);
}

.resultsSeperator {
  font-weight: 500;
  font-size: 0.9em;
  margin-top: 1px;
  color: rgb(255, 255, 255, 0.55);
}

.resultsBetDollar {
  font-weight: 400;
  font-size: 0.9em;
  margin-top: 2px;
  color: rgb(182, 228, 149);
}

.resultsTag {
  font-weight: 200;
  font-size: 0.4em;
  text-align: left;
  margin-left: 0.2em;
  line-height: 0.85em;
  color: rgb(255, 255, 255, 0.35);
}

.resultsWinnings {
  
  border: 1px solid white;
}
.resultsEth {
  color: rgb(255, 255, 255, .55);
  font-size: 0.75em;
  line-height: 3em;;
}

.overlay {
  padding:0;
  margin:0;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  z-index: 10;
  position: fixed; 
}

.overlayBackground {
  opacity:    0.65; 
  background: #000; 
  width:      100%;
  height:     100%; 
  top:        0; 
  left:       0; 
  z-index:    9;
  position:   fixed; 
}

#outer {
  position: relative;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  background: rgb(182, 228, 149);
  height: 100px;
  width: 100px;
  border-radius: 15px;
}
#middle{
  height: 60px;
  width: 60px;
  margin:auto;
  position: relative;
  top:20px;
  border-radius: 50%;
  background-image: linear-gradient(150deg, transparent 50%, #0f9000 50%),linear-gradient(90deg, #0f9000 50%, white 50%);
  -webkit-animation: rotation 1200ms infinite linear;
  animation: rotation 1200ms infinite linear;
  transform-origin: 50% 50%;
  animation-timing-function: ease;
}
#inner{
  background: rgb(182, 228, 149);
  height: 48px;
  width: 48px;
  margin:auto;
  position: relative;
  top:6px;
  border-radius: 50%;
}
@-webkit-keyframes rotation {
from {
  -webkit-transform: rotate(270deg);
}
to{
  -webkit-transform: rotate(630deg);
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(270deg);
  }
  to{
    -webkit-transform: rotate(630deg);
    }
  }